<template>
  <div v-if="value">
    <div v-for="(item, key) in value.value" :key="item">
      {{ getCode(value.code, key) }} - {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ProReportCommodityDropDownListTdField.vue",
  props: {
    value: null,
    filterOption: {},
  },
  methods: {
    getCode(item, key) {
      if (item.length === 0) return "";
      return item.slice(0, key + 1).join("-");
    },
  },
};
</script>
